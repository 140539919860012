import {Component, inject, PLATFORM_ID} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoadingService} from "../services/loading.service";
import {combineLatest} from "rxjs";
import {finalize} from "rxjs/operators";
import {commonModuleImports} from "../app.imports";
import {UserData} from "../abstract/users";
import {UserAccessService} from "../services/auth.service";
import {CommonInfoPageComponent} from "../common-info-page/common-info-page.component";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-register',
  standalone: true,
    imports: [...commonModuleImports, CommonInfoPageComponent],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent{
  form: FormGroup;
  private accessService: UserAccessService = inject(UserAccessService);
  private platformId: Object = inject(PLATFORM_ID);

  constructor(
    private titleService: Title,
    private userService: UserData,
    private loadingService: LoadingService,
    private router: Router,
    formBuilder: FormBuilder,
  ) {
    this.titleService.setTitle("Registration | Burvix Traders");
    this.form = formBuilder.group({
      "acceptTerms": [false, [Validators.requiredTrue]],
      "acceptPrivacy": [false, [Validators.requiredTrue]],
      "getMarketInfo": [false, []]
    });

    if(isPlatformBrowser(this.platformId)) {
      this.router.navigate(['/my']);
      return;
    }

    let isUser = this.accessService.isInRole('user');
    let isRegistered = this.accessService.isRegistered();
    combineLatest([isUser, isRegistered]).subscribe(res => {
      if (!res[0] || res[1]) this.router.navigate(['/my']);
    });
  }

  register() {
    this.loadingService.openLoader();

    let user = this.userService.updateUserProfile({
      receiveMarketingInfo: this.form.controls['getMarketInfo'].value,
      refCode: localStorage.getItem('refCode')
    });
    combineLatest([user]).pipe(
      finalize(() => this.loadingService.closeLoader()),
    ).subscribe(_ => {
      this.userService.clearCache();
      this.loadingService.openLoader();
      let getUser = this.userService.getMyUser();
      combineLatest([getUser]).pipe(
        finalize(() => this.loadingService.closeLoader()),
      ).subscribe(res => {
          this.accessService.setUser(res[0]);
          this.router.navigate(['/my']);
        });
    });
  }
}
