import {Component, inject} from '@angular/core';
import {UserAccessService} from "../services/auth.service";
import {commonImports} from "../app.imports";

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [...commonImports]
})
export class LoginComponent {
  private readonly userAccessService = inject(UserAccessService);

  login(){
    this.userAccessService.authorize();
  }
}
