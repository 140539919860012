import {Component, inject, input, OnInit, PLATFORM_ID} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {
  BlockWithHeaderComponent,
  CommonPageInfo,
  DefinitionsBlockComponent,
  DynamicPageBlock,
  InfoData
} from '../abstract/info';
import {commonImports} from "../app.imports";
import {cast} from "../app.config";
import {isPlatformBrowser} from "@angular/common";

@Component({
  imports: [...commonImports],
    selector: 'ngx-common-info-page',
    standalone: true,
    templateUrl: './common-info-page.component.html',
    styleUrls: ['./common-info-page.component.scss']
})
export class CommonInfoPageComponent implements OnInit {
    public item?: CommonPageInfo;
    private platformId: Object = inject(PLATFORM_ID);
    currentPage?: string;
    inputPage = input<string>();

    constructor(
        private readonly titleService: Title,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly infoService: InfoData
    ) {
        this.titleService.setTitle('Burvix Traders');
    }

    ngOnInit(): void {
        this.route.params.subscribe(({page}) => {
          if(this.inputPage())
            page= this.inputPage();
          this.currentPage = page;
          this.loadInfoContent(page);
        });
    }

    asBlockWithHeaderComponent(block: DynamicPageBlock) {
      return cast<BlockWithHeaderComponent>(block);
    }

    asDefinitionsBlockComponent(block: DynamicPageBlock) {
      return cast<DefinitionsBlockComponent>(block);
    }

    loadInfoContent(slug: string) {
      this.infoService.getDataCollection<CommonPageInfo>(
        slug,
        ['[blocks][populate]=*'],
        [],
        null,
        null,
        null).subscribe(res => {
        if (!res.data) {
          this.router.navigate(['/404']);
          return;
        }
        this.item = res.data;
        this.titleService.setTitle(`${this.item.attributes.title} | Burvix Traders`);
        if(isPlatformBrowser(this.platformId)) {
          window.scroll(0, 0);
        }
      });
    }
}
