<div class="container-log">
  <div class="openner">
    <div class="main-openner">

      <div class="text-openner">
        <h1>Let’s get started</h1>
        <p>No need to pay, simple registration, you can see the main functions and ask us questions</p>

        <div class="button-container"><a class="box sign-up new-padding" (click)="login()">Sign Up via Gmail</a></div>
      </div>

    </div>

    <div class="image-container">
      <img ngSrc="/assets/images/openner-pic.png" alt="openner-pic" width="1920" height="1080" priority>
    </div>

    <div class="mob-ver">
      <img class="img-inner" ngSrc="/assets/images/mob-openner.png" alt="mob-openner" width="375" height="868" priority>
      <img class="frame" ngSrc="/assets/images/mob-frame.svg" alt="mob-frame" width="375" height="705" priority>
    </div>

  </div>
</div>
