<ngx-personal-area-common>
  <div class="main-page">
    @defer(when isLoaded) {
      <div class="exchanges-block">
        <div class="exchanges">
          @for (item of exchanges; track $index) {
            <div class="exchange" [ngClass]="{'active': item.isActive, 'notification': item.notification}" (click)="setActive(item)">
              <div class="image">
                <img [ngSrc]="item.icon" [alt]="item.name" width="40" height="44" priority>
              </div>
              <div class="name">
                <h3>{{item.name}}</h3>
                <p>{{item.link}}</p>
              </div>
            </div>
          }
        </div>

        @if(isLoaded && botIds.length > 0 && !forceApi || requestConnect) {
          <ngx-bot-dashboard [botIds]="botIds" [exchange]="userSelectedExchange" [invoices]="exchangeInvoices"></ngx-bot-dashboard>
        } @else if(isLoaded && userSelectedExchange) {
          <app-connect-bot-page [exchange]="userSelectedExchange"></app-connect-bot-page>
        }

      </div>
    }
  </div>
</ngx-personal-area-common>
