import { Component } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {commonImports} from "../app.imports";
import {LoadingService} from "../services/loading.service";
import {User, UserData} from "../abstract/users";

@Component({
  selector: 'ngx-admin-users',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent {

  users: User[] = [];

  constructor(
    private userService: UserData,
    private loadingService: LoadingService,
    private router: Router,
    private title: Title,
  ) {
    this.loadingService.openLoader();
    this.title.setTitle('Admin panel | Burvix Traders');
    this.userService.getUsers().pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.users = res;
    });
  }

  selectUser(row: User) {
    this.router.navigate([`/awesome-admin/${row.email}/my`]);
  }

}
