<ngx-common-info-page inputPage="terms-and-conditions">
  <div class="check" [formGroup]="form">
    <div class="check-inner">
      <div class="checkbox-group">
        <label class="custom-checkbox">
          <input formControlName="acceptTerms" type="checkbox" />
          <span class="checkmark"></span>
          I accept the terms of service
        </label>
        <label class="custom-checkbox">
          <input formControlName="acceptPrivacy" type="checkbox" />
          <span class="checkmark"></span>
          I accept the Privacy Policy
        </label>
        <label class="custom-checkbox">
          <input formControlName="getMarketInfo" type="checkbox" />
          <span class="checkmark"></span>
          I agree to receive newsletter, promotions, and special offers from Burvix Traders
        </label>
      </div>

      <button class="box custom-button" (click)="register()" [disabled]="!form.valid">Register</button>
    </div>
  </div>

</ngx-common-info-page>
