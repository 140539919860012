import {AbstractSecurityStorage, PassedInitialConfig} from 'angular-auth-oidc-client';
import {environment} from "../../environments/environment";
import {Injectable} from "@angular/core";

export const authConfig: PassedInitialConfig = {
  config: {
            authority: 'https://accounts.google.com',
            redirectUrl: environment.googleRedirectUrl,
            postLogoutRedirectUri: '/',
            postLoginRoute: '/my',
            clientId: environment.googleClientId,
            checkRedirectUrlWhenCheckingIfIsCallback: false,
            scope: 'email profile openid', // 'openid profile ' + your scopes
            responseType: 'id_token token',
            silentRenew: false,
            silentRenewUrl: environment.silentRenewUrl,
            renewTimeBeforeTokenExpiresInSeconds: 10,
            secureRoutes: [environment.apiUrl],
            unauthorizedRoute: '/my'
        }
}

@Injectable({providedIn: 'root'})
export class LocalStorageService implements AbstractSecurityStorage {
  read(key: string) {
    return localStorage.getItem(key);
  }

  write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
