import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LoadingService } from "./loading.service";
import { map } from "rxjs/operators";
import {UserAccessService} from "./auth.service";
import {isPlatformBrowser} from "@angular/common";
import {of} from "rxjs";

@Injectable()
export class AdminAuthGuardService implements CanActivate {

  constructor(
    private accessChecker: UserAccessService,
    private loadingService: LoadingService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!isPlatformBrowser(this.platformId)) return of(false);

    return this.accessChecker.isInRole('admin').pipe(
        map(res => {
            if(res){
                return res;
            }

            this.loadingService.setRedirectUrl(state.url);
            this.router.navigate(['/login']);

            return false;
        })
    );
  }
}
