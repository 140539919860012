@if(isLoading()){
  <div class="loading-shade">
    <ngx-loader></ngx-loader>
  </div>
}
<ngx-header></ngx-header>

<div class="main-body">
  <router-outlet />
</div>

<ngx-footer></ngx-footer>
