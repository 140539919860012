<ngx-personal-area-common>
    <div class="setting-container">
        <div class="setting-container-img">
            <img class="user-image" [ngSrc]="userImageUrl" alt="userPhoto" width="44" height="44" />
        </div>

        <div class="setting-container-data">
            <div class="block name">
                <p class="header-params">Name</p>
                <h5>{{user?.name}}</h5>
            </div>

            <div class="block email">
                <p class="header-params">Email</p>
                <h5>{{user?.email}}</h5>
                <p>To change your email please contact support</p>
            </div>

            <div class="block main-info">
                <p class="header-params">Main Information</p>

                <div class="block-inner">
                    <p>Date of Registration</p>
                    <h5>{{user?.registeredOn | date:'dd.MM.yyyy'}}</h5>
                </div>

                <div class="social" [formGroup]="form">
                    <div class="block-inputs">
                        <div class="block-inner">
                            <p>Phone</p>
                            <input formControlName="phone" placeholder="Enter your Phone here" type="tel">
                        </div>
                        <div class="block-inner">
                            <p>Telegram</p>
                            <input formControlName="telegramAccount" placeholder="Enter your Telegram link here" type="text">
                        </div>
                    </div>

                    <div class="block-inner">
                        <p>Whatsapp</p>
                        <input formControlName="whatsApp" placeholder="Enter your Whatsapp link here" type="text">
                    </div>
                </div>

                <div class="block-inner">
                    <p>My Referral Link</p>
                    <a [href]="link">{{link}}</a>
                </div>
            </div>
            <div class="block buttons">
                <button [disabled]="!canSubmit"  (click)="save()" class="box save">Save</button>
                <button (click)="contact()" class="box contact">Contact Support</button>
            </div>
        </div>
    </div>
</ngx-personal-area-common>
