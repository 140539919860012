import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {isPlatformBrowser, Location} from '@angular/common';
import { filter } from 'rxjs/operators';
import {Title} from "@angular/platform-browser";
import {UserAccessService} from "../../services/auth.service";

@Injectable({providedIn: 'root'})
export class AnalyticsService {
  private enabled: boolean;

  constructor(private location: Location, private router: Router, @Inject(PLATFORM_ID) platformId: Object, private titleService: Title, private accessService: UserAccessService) {
    this.enabled = isPlatformBrowser(platformId);
    this.accessService.getUser().subscribe(user => {
      this.setValue({
        user_id: user ? btoa(user.email) : undefined,
      });
    })
  }

  trackPageViews() {
    if (this.enabled) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
      )
        .subscribe(() => {
          AnalyticsService.sendEvent('event', 'page_view', {page_title: this.titleService.getTitle(), page_location: this.location.path()});
        });
    }
  }

  trackEvent(eventName: string) {
    if (this.enabled) {
      //window['gtag']('send', 'event', eventName);
    }
  }

  static trackException(error: any, isFatal: boolean) {
    if ((<any>window).gtag) {
      AnalyticsService.sendEvent('event', 'exception', {
        'description': JSON.stringify(error, Object.getOwnPropertyNames(error)),
        'fatal': isFatal
      });
    }
  }

  static sendEvent(event: string, type: string, data: any){
    (<any>window).gtag(event, type, data);
  }

  private setValue(data: any){
    (<any>window).gtag('set', data);
  }
}
