<div class="container-glossary">
  @defer (when page) {
    <div class="header-block">
      <div class="header-text">
        <span class="icon"></span>
        <h1 class="gradient-text">{{ page!.attributes.title }}</h1>
        <span class="icon mod-display-none"></span>
      </div>

      <div class="search-panel">
        <div class="search">
          <span class="search-icon" (click)="onSearch()"></span>
          <input type="text" [(ngModel)]="searchTerm" [placeholder]="page!.attributes.search">
        </div>
        <div class="alphabet">
          <span *ngFor="let letter of page!.attributes.letters"
            [ngClass]="{ 'active': groupedItems[letter] }"
            (click)="scrollToSection(letter)">{{ letter }}
          </span>
        </div>
      </div>

      <img class="grid" ngSrc="/assets/images/grid.png" alt="grid" width="1662" height="819">

      @if(item) {
        <div class="info-block" *ngIf="item">

          <div class="info-block-inner" *ngFor="let group of filteredItems | keyvalue" [ngClass]="{ 'active': true }">
            <div class="letter">
              <h2 [id]="group.key">{{ group.key }}</h2>
            </div>
            <div class="letter__text-block">
              <div class="text-block-inner" *ngFor="let item of group.value">
                <h3>{{ item.attributes.name }}</h3>
                <ngx-text-block [blocks]="item.attributes.body"></ngx-text-block>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>
