import { Component } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import { Title } from '@angular/platform-browser';
import {BotRunInfo, UserData, ReferralInfo} from '../abstract/users';
import {commonImports} from "../app.imports";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../common/pop-up/pop-up.component";

@Component({
    selector: 'app-create-invoice',
    standalone: true,
    imports: [...commonImports],
    templateUrl: './create-invoice.component.html',
    styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent {
    botId: string = '';
    userId: string = '';
    form: FormGroup = null!;
    referralBalance: number = 0;
    referralInfo: ReferralInfo = null!;
    runs: BotRunInfo[] = [];
    selectedRun: number = 0;
    settings: any = {};
    selectableRuns:number[] = [];

    get isFormValid(): boolean {
      return this.form?.valid || false;
    }

    get startDate() {
      if (!this.selectedRun) return null;

      return this.runs[0].startDate;
    }

    get endDate() {
      if (!this.selectedRun) return null;

      for (let i = 0; i < this.runs.length; i++) {
        if (this.runs[i].id === this.selectedRun) return this.runs[i].endDate;
      }

      return null;
    }
    get totalProfit() {
      if (!this.selectedRun) return 0;

      let profit = 0;
      for (let i = 0; i < this.runs.length; i++) {
        profit += this.runs[i].profit;
        if (this.runs[i].id === this.selectedRun) {
          return profit;
        }
      }

      return profit;
    }
    get runIds() {
      if (!this.selectedRun) return [];

      const res = [];
      for (let i = 0; i < this.runs.length; i++) {
        res.push(this.runs[i].id);
        if (this.runs[i].id === this.selectedRun) {
          return res;
        }
      }

      return res;
    }

    constructor(
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserData,
        private title: Title,
        private readonly dialogService: MatDialog,
        formBuilder: FormBuilder) {
          this.route.paramMap.subscribe(params => {
            this.botId = params.get('botId')!;
            this.userId = params.get('userId')!;
            this.title.setTitle(`Create new invoice for ${this.botId} | Burvix Bot`);

            this.form = formBuilder.group({
              'email': [this.userId, Validators.required],
              'runId': ['', [Validators.required]],
              'totalAmount': ['', [Validators.required]],
              'amountToPay': ['', [Validators.required]],
              'amountToPayDescription': ['30% of the profit', [Validators.required]],
              'skipInvoicePayment': [false, []],
              'referralBalance': [0, []]
            });

            this.loadData();
          });
    }

    loadData() {
      this.loadingService.openLoader();
      const runs = this.userService.getUserBotRuns(this.botId);
      const referral = this.userService.getUserReferralInfo(this.userId);

      combineLatest([runs, referral]).pipe(
        finalize(() => this.loadingService.closeLoader())
      ).subscribe(res => {
        this.referralInfo = res[1];
        this.runs = res[0][this.botId].sort((n1, n2) => {
          if (n1.startDate > n2.startDate) {
            return 1;
          }

          if (n1.startDate < n2.startDate) {
            return -1;
          }

          return 0;
        });

        let canSelect = true;
        for (const item of this.runs) {
          if (item.excludedOrders.length > 0) {
            canSelect = false;
          }

          if (canSelect) this.selectableRuns.push(item.id);
        }

        this.referralBalance = (this.referralInfo?.totalReward - this.referralInfo?.receivedReward) || 0;

        this.form.get('referralBalance')!.clearValidators();
        this.form.get('referralBalance')!.addValidators([Validators.required, Validators.max(this.referralBalance)]);

        this.form.patchValue({
          referralBalance: this.referralBalance
        });
      });
    }

    canSelect(i: number) {
      return this.selectableRuns.indexOf(i) >= 0;
    }

    selectRun(i: number) {
      if (!this.canSelect(i)) return;

      this.selectedRun = i;

      this.form.get('runId')!.setValue(i);
      this.form.get('totalAmount')!.setValue(Math.floor(this.totalProfit * 100) / 100);
      this.form.get('amountToPay')!.setValue(Math.floor(this.totalProfit * 30) / 100);
    }

    submitSettings() {
      const value = {
        amount: this.form.value.totalAmount,
        amountToPay: this.form.value.amountToPay,
        amountToPayDescription: this.form.value.amountToPayDescription,
        approveInvoice: this.form.value.skipInvoicePayment
      };

      this.dialogService.open(PopUpComponent, {
        data: {
          title: 'Are you sure?',
          message:`You are going to create new invoice at profit ${value.amount}.`,
          cancelButtonText: 'Cancel',
          saveButtonText: 'Delete',
        }
      }).afterClosed().subscribe(res => {
        if(!res) return;

        const invoice = this.userService.createInvoice(
          this.userId,
          this.runIds,
          value.amount,
          value.amountToPay,
          value.amountToPayDescription,
          value.approveInvoice
        );

        this.loadingService.openLoader();
        combineLatest([invoice]).pipe(
          finalize(() => this.loadingService.closeLoader())
        ).subscribe(_ => {
          alert(`Invoice for profit ${value.amount} has been created`);
          this.selectableRuns = [];
          this.selectedRun = -1;
          this.form.reset();
        });
      });
    }

  deleteRun(item: BotRunInfo) {
    this.dialogService.open(PopUpComponent, {
      data: {
        title: `Do you want to delete Bot Run #${item.id} with profit ${item.profit}?`,
        message: `You are going to delete Bot Run #${item.id}.`,
        cancelButtonText: 'Cancel',
        saveButtonText: 'Delete',
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.loadingService.openLoader();
        this.userService.deleteUserBotRun(this.userId, item.id).pipe(
          finalize(() => this.loadingService.closeLoader())
        ).subscribe(_ => {
          this.loadData();
        });
      }
    });
  }
 }
