<div class="error">
  <img class="not-found" ngSrc="/assets/images/404.png" alt="404" width="1383" height="667">
  <img class="not-found-mob" ngSrc="/assets/images/404-phone.png" alt="404" width="375" height="1121">

  <div class="error-inner">

    <div class="text-inner">
      <h1>The page not Found</h1>
      <p>The page not found. Please, go back to the Main page.</p>
    </div>

    @if(!isAuthenticated) {
      <button class="box contact" routerLink="/">Go to Main Page</button>
    } @else {
      <button class="box contact" routerLink="/my">Go to Main Page</button>
    }

  </div>

  <img class="frame" ngSrc="/assets/images/frame.svg" alt="frame" width="1920" height="1031">
</div>
