 <div class="header">
  <div class="header-inner">
    <div class="header-container">
      <div class="logo-container">
        @if(!user) {
          <a class="logo" routerLink="/"></a>
        } @else {
          <a class="logo" routerLink="/my"></a>
        }

      </div>
    </div>

    <div class="menu-links">
      @if(!user) {
        <a href="javascript:void(0)" (click)="scrollToElement('api-keys')">API Keys</a>
        <a href="javascript:void(0)" (click)="scrollToElement('fees-payments')">Payments</a>
        <a href="javascript:void(0)" (click)="scrollToElement('our-strategies')">Strategies</a>
        <a href="javascript:void(0)" (click)="scrollToElement('contact-us')">Contact Us</a>
        <a href="javascript:void(0)" (click)="scrollToElement('core-team')">Core Team</a>
      }
    </div>

    <div class="burger-menu-res">
      <svg (click)="toggleMenu()" [class]="{'open': isOpen}" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect class="top" x="0.875" y="3" width="30" height="4" rx="1" fill="url(#paint0_linear_1890_7098)"/>
        <rect class="middle" x="0.875" y="13" width="30" height="4" rx="1" fill="url(#paint1_linear_1890_7098)"/>
        <rect class="bottom" x="0.875" y="23" width="30" height="4" rx="1" fill="url(#paint2_linear_1890_7098)"/>
        <defs>
          <linearGradient id="paint0_linear_1890_7098" x1="15.875" y1="3" x2="15.875" y2="7" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" offset=""/>
            <stop offset="1" stop-color="#EDF3FA"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1890_7098" x1="15.875" y1="13" x2="15.875" y2="17" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" offset=""/>
            <stop offset="1" stop-color="#EDF3FA"/>
          </linearGradient>
          <linearGradient id="paint2_linear_1890_7098" x1="15.875" y1="23" x2="15.875" y2="27" gradientUnits="userSpaceOnUse">
            <stop stop-color="white" offset=""/>
            <stop offset="1" stop-color="#EDF3FA"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="user-icons">

      <div style="display: none;" class="dropdown">
        <button class="dropbtn" id="current-lang-btn">EN<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 6L9.33013 0H0.669873L5 6Z" fill="white"/>
        </svg>
        </button>
        <div class="dropdown-content" id="language-dropdown"><a href="https://manimamaeu.pp.ua/about-us/our-team/" hreflang="en-US" lang="en">English</a>

          <a href="https://manimamaeu.pp.ua/uk/pro-nas/partneri/" hreflang="uk-UA" lang="uk-UA">Українська</a><a href="https://manimamaeu.pp.ua/ru/komanda/" hreflang="ru-RU" lang="ru-RU">Русский</a></div>
      </div>

      <div class="header-container icons">

        @if(user !== undefined) {
          <div class="user-panel">
            <div class="user">
              <img class="user-image" [ngSrc]="userImageUrl" width="46" height="46" alt="photo" priority/>
              <p class="user-name">{{user.name}}</p>
            </div>
          </div>
        } @else {
          <div class="button-container">
            <p style="display: none;">Already have an account?</p>
            <a (click)="login()" class="box sign-in">Sign In</a>
            <a (click)="login()" class="box sign-up">Sign Up</a>
          </div>
        }
      </div>
    </div>
  </div>

  @if(isOpen){
    <div class="menu-mob">
      <ul>
        @if(!user) {
          <li><a href="javascript:void(0)" (click)="scrollToElement('api-keys'); closeMenu()">API Keys</a></li>
          <li><a href="javascript:void(0)" (click)="scrollToElement('fees-payments'); closeMenu()">Payments</a></li>
          <li><a href="javascript:void(0)" (click)="scrollToElement('our-strategies'); closeMenu()">Strategies</a></li>
          <li><a href="javascript:void(0)" (click)="scrollToElement('contact-us'); closeMenu()">Contact Us</a></li>
          <li><a href="javascript:void(0)" (click)="scrollToElement('core-team'); closeMenu()">Core Team</a></li>
        } @else{
          @for(item of menu; track item) {
            @if(hasAccess(item.role) | async) {
              <li
                [routerLink]="item.link"
                [class]="{ active: isActive(item.link) }"
                (click)="closeMenu()"
              >
                <img [ngSrc]="item.icon" alt="item.icon" width="24" height="24"> {{item.name}}</li>
            }
          }
          @for(item of bottomMenu; track item) {
            @if(hasAccess(item.role) | async) {
              <li [class]="{ active: isActive(item.link) }" (click)="item.action(); closeMenu()"><img [ngSrc]="item.icon" alt="item.icon" width="24" height="24"> {{item.name}}</li>
            }
          }
        }
      </ul>

      <!-- future language switcher -->
<!--      <div class="dropdawn">-->
<!--        <button>EN <img src="assets/images/Polygon 1.svg" alt=""></button>-->
<!--        <div class="list">-->

<!--        </div>-->
<!--      </div>-->

      <div class="icons">
        <a href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;to=support@burvix.io" target="_blank"><img ngSrc="/assets/images/mail.svg" alt="mail" width="30" height="24"></a>
        <a href="https://t.me/manimama_legal" target="_blank"><img ngSrc="/assets/images/telegram.svg" alt="telegram" width="28" height="23"></a>
        <a href="https://www.facebook.com/manimama.eu" target="_blank"><img ngSrc="/assets/images/facebook.svg" alt="facebook" width="18" height="28"></a>
        <a href="https://www.linkedin.com/company/79972677/" target="_blank"><img ngSrc="/assets/images/linked-in.svg" alt="linked-in" width="30" height="30"></a>
      </div>
    </div>
  }
</div>
