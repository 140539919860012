<ngx-personal-area-common>
    <div class="payments">
        <h4>Bots</h4>
      <div class="container">
        <table class="table-payments">
          <thead>
          <tr>
            <th>ID</th>
            <th>Last Launch</th>
            <th>Start Amount</th>
            <th>Exchange</th>
            <th>Trading pair</th>
            <th>Mode</th>
            <th class="action">Action</th>
          </tr>
          </thead>
          <tbody>
          @for (item of items; track item; let idx = $index, e = $even) {
          <tr [class]="{'another': !e}">
                        <th>{{item.botId}}</th>
                        <th>{{item.startedOn | date:'dd.MM.yyyy'}}</th>
                        <th>{{item.startAmount | ngxNumberWithCommas}}</th>
                        <th>{{item.exchangeType}}</th>
                        <th>{{item.cryptoCoin}}/{{item.baseCoin}}</th>
                        <th>{{item.isInBuyMode ? 'LONG' : 'SHORT'}}</th>
                        <th><a [routerLink]="['/awesome-admin/', userId, 'my', 'bots', item.botId]" class="pay-invoice">Create invoice</a></th>
          </tr>
          }
          </tbody>
        </table>
      </div>
    </div>
</ngx-personal-area-common>

