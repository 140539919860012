import { Component } from '@angular/core';
import {Title} from '@angular/platform-browser';
import {CarouselItem} from '../common/carousel/carousel.component';
import {commonImports} from "../app.imports";
import {InfoData, ListItemChild, MainPageAttributes, MainPageInfo} from "../abstract/info";
import {cast} from "../app.config";


@Component({
  imports: [...commonImports],
  selector: 'ngx-landing-page',
  standalone: true,
  styleUrls: ['./landing-page.component.scss'],
  templateUrl: './landing-page.component.html'
})
export class LandingPageComponent {
  year: number;
  mainPage?: MainPageAttributes;
  knowledgeMap: CarouselItem = {
    title: 'title',
    description: 'shortDescription',
    icon: 'iconUrl',
    slug: 'slug',
    order: 'order'
  };

  sizeMap: { [key: string]: { width: number; height: number } } = {
    'binance': { width: 114, height: 114 },
    'white-bit': { width: 120, height: 114 },
    'okx': { width: 116, height: 114 },
    'kuna': { width: 84, height: 114 },
  };

  constructor(
    private titleService: Title,
    private infoService: InfoData
  ) {
    this.titleService.setTitle('Burvix Traders');
    this.year = (new Date()).getFullYear();
    this.infoService.getDataCollection<MainPageInfo>(
      'page',
      [
        '[Body][populate]=*'
      ],
      [],
      null,
      null,
      null
    ).subscribe(res => {
      this.mainPage = res.data.attributes;
    })
  }

  getSize(slug: string): { width: number; height: number } {
    return this.sizeMap[slug] || { width: 50, height: 50 };
  }

  convertToTextBlock(block:any, field: string){
    return cast<ListItemChild[]>(block[field]);
  }

}
