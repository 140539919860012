import { Observable } from "rxjs";
import { ExchangeConnectInfo, ExchangeData, ExchangeInfo, ExchangeRegisterInfo} from "../abstract/exchange";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable()
export class ExchangeService extends ExchangeData {

    constructor(private http: HttpClient){
        super();
      }

    getRegisteredExchanges(): Observable<ExchangeInfo[]> {
        return this.http.get<ExchangeInfo[]>(`${environment.apiUrl}/exchange`);
    }

    getUserRegisteredExchanges(userId: any): Observable<ExchangeInfo[]> {
      return this.http.get<ExchangeInfo[]>(`${environment.apiUrl}/admin/users/${userId}/exchanges`);
    }

    getBinanceExchange(): Observable<ExchangeInfo> {
        return this.http.get<ExchangeInfo>(`${environment.apiUrl}/exchange/Binance`);
    }

    getKunaExchange(): Observable<ExchangeInfo> {
        return this.http.get<ExchangeInfo>(`${environment.apiUrl}/exchange/Kuna`);
    }

    getWhiteBitExchange(): Observable<ExchangeInfo> {
        throw new Error("Method not implemented.");
    }

    getOkxExchange(): Observable<ExchangeInfo> {
      return this.http.get<ExchangeInfo>(`${environment.apiUrl}/exchange/OKX`);
    }

    registerBinance(info: ExchangeRegisterInfo): Observable<any> {
        return this.http.post(`${environment.apiUrl}/exchange/binance`, info);
    }

    registerKuna(info: ExchangeRegisterInfo): Observable<any> {
        return this.http.post(`${environment.apiUrl}/exchange/kuna`, info);
    }

    registerWhiteBit(info: ExchangeRegisterInfo): Observable<any> {
        return this.http.post(`${environment.apiUrl}/exchange/whitebit`, info);
    }

    registerOkx(info: ExchangeRegisterInfo): Observable<any> {
      return this.http.post(`${environment.apiUrl}/exchange/okx`, info);
    }

    deleteBinance(comment: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/exchange/binance`, {
            body: {
                comment: comment
            }
        });
    }

    deleteKuna(comment: string): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/exchange/kuna`, {
            body: {
                comment: comment
            }
        });
    }

    deleteWhiteBit(comment: string): Observable<any> {
        throw new Error("Method not implemented.");
    }

    deleteOkx(comment: string): Observable<any> {
      return this.http.delete(`${environment.apiUrl}/exchange/okx`, {
        body: {
          comment: comment
        }
      });
    }

    verifyApiKeys(exchange: string, api: string, secret: string, passphrase: string, useOAuthData: boolean): Observable<ExchangeConnectInfo> {
      return this.http.post<ExchangeConnectInfo>(`${environment.apiUrl}/exchange/${exchange}/connect`, {
        apiKey: api,
        secretKey: secret,
        passphrase: passphrase,
        useOAuthData: useOAuthData
      });
    }

    getOauthLink(exchange: string): Observable<string> {
      return this.http.get(`${environment.apiUrl}/exchange/${exchange}/oauth`, {responseType: "text", withCredentials: true});
    }
}
