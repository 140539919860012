import { Component } from '@angular/core';
import {commonModuleImports} from "../app.imports";

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [...commonModuleImports],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {

}
