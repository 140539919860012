<ngx-personal-area-common>
    <div class="referral-program">
        <div class="block link-block">
            <h4>Referral Program</h4>

            <div class="ref-block">
                <div class="inner-block">
                  <h5>Your referral link:</h5>
                  <div class="link">
                    <div class="custom-input"><a [href]="link" target="_blank">{{link}}</a></div>

                    <button class="copy-link-btn" (click)="socialClickShare('copy')"><img ngSrc="/assets/images/copy-ref.svg" alt="copy-ref" width="24" height="24"></button>
                  </div>
                </div>

                <div class="inner-block">
                    <h5>Invite a friend:</h5>

                    <div class="buttons">
                        <button (click)="socialClickShare('twitter')"><img ngSrc="/assets/images/X_logo.svg" alt="X_logo" width="30" height="26"></button>
                        <button (click)="socialClickShare('facebook')"><img ngSrc="/assets/images/facebook-f.svg" alt="facebook" width="30" height="30"></button>
                        <button (click)="socialClickShare('telegram')"><img ngSrc="/assets/images/telegram-plane.svg" alt="telegram" width="30" height="30"></button>
                        <button (click)="socialClickShare('whatsapp')"><img ngSrc="/assets/images/whatsapp.svg" alt="whatsapp" width="30" height="30"></button>
                        <button (click)="socialClickShare('copy')"><img ngSrc="/assets/images/yellow-copy.svg" alt="yellow" width="30" height="30"></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="block referral-details">
            <h4>Referral Details</h4>
            <table class="table-details">
                <tr>
                  <th>
                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/dollar-circle.svg" alt="dollar-circle" width="22" height="22"></span> {{data ? data.rewardPercent : 0}}%</h6>
                      <p>Your commission</p>
                    </div>
                  </th>
                  <th>

                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/wallet.svg" alt="dollar-circle" width="25" height="24"></span> {{data ? data.totalReward : 0}}$</h6>
                      <p>Total earnings</p>
                    </div>
                  </th>
                  <th>

                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/spent-dollar.svg" alt="dollar-circle" width="25" height="24"></span> {{data ? data.receivedReward : 0}}$</h6>
                      <p>Already spent</p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>

                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/wallet.svg" alt="dollar-circle" width="25" height="24"></span> {{data ? currentBalance : 0}}$</h6>
                      <p>Current balance</p>
                    </div>
                  </th>
                  <th>

                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/referrals.svg" alt="dollar-circle" width="25" height="24"></span> {{data ? data.clients.length : 0}}</h6>
                      <p>Referrals</p>
                    </div>
                  </th>
                  <th>

                    <div class="statistic-block">
                      <h6><span><img ngSrc="/assets/images/ref-icons-table/started-referrals.svg" alt="dollar-circle" width="25" height="24"></span> {{data ? data.startedCountClients : 0 }}</h6>
                      <p>Referrals who started trading</p>
                    </div>
                  </th>
                </tr>
              </table>
        </div>

        <div class="block clients">
            <h4>Referred Clients</h4>

            <table class="table-clients">
                <thead>
                    <tr>
                        <th>Client ID</th>
                        <th>Date of Registration</th>
                        <th>Earned</th>
                    </tr>
                </thead>
                <tbody>
                  @defer (when data) {
                    @for (item of data.clients; track item.userId; let idx = $index, e = $even) {
                      <tr [class]="{'another': !e}">
                        <th>{{item.userId}}</th>
                        <th>{{item.registeredDate | date:'dd.MM.yyyy' }}</th>
                        <th>
                          <p>{{item.earned}}$</p>
                        </th>
                      </tr>
                    }
                  }
                </tbody>
            </table>
        </div>
    </div>
</ngx-personal-area-common>
