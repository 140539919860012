import { afterNextRender, Component, inject } from '@angular/core';
import {commonImports} from "../app.imports";
import { User, UserData } from '../abstract/users';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'ngx-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
  standalone: true,
  imports: [...commonImports]
})
export class NotFoundComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  isAuthenticated: boolean = false;

  constructor(private userService: UserData){
    afterNextRender(()=> {
      this.oidcSecurityService
        .checkAuth()
        .subscribe((loginResponse: LoginResponse) => {
          const { isAuthenticated, userData, accessToken, idToken, configId } =
              loginResponse;
          if(isAuthenticated) {
            this.isAuthenticated = true;
          } else {
            this.isAuthenticated = false;
          }
        });
    });
  }
}
