import {afterNextRender, Component} from '@angular/core';
import { commonImports } from '../app.imports';
import { BotShortInfo, UserData} from '../abstract/users';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { Title } from '@angular/platform-browser';
import { combineLatest, finalize } from 'rxjs';

@Component({
  selector: 'app-admin-nots',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './admin-bots.component.html',
  styleUrl: './admin-bots.component.scss'
})
export class AdminBotsComponent {
  userId!: string;
  items: BotShortInfo[] = [];

  constructor(
    private route: ActivatedRoute,
    private userService: UserData,
    private loadingService: LoadingService,
    private titleService: Title
  ) {
    afterNextRender(() => {
      window.scrollTo(0, 0);
    });
    this.titleService.setTitle('Admin Bots | Burvix Traders');
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId') || '';
      this.loadPage();
    });
  }

  formatPair(item: any) {
    let res = item.cryptoCoin;

    if (item.baseCoin) {
      res = res + '/' + item.baseCoin;
    }

    return res;
  }

  loadPage() {
    this.loadingService.openLoader();

    const bots = this.userService.getUserShortBots(
        this.userId,
        true
      );

    combineLatest([bots]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.items = res[0];
    });
  }
}
