import {ChangeDetectorRef, Component, ElementRef, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {CarouselItem} from '../common/carousel/carousel.component';
import {FilterType, InfoData, KnowledgeBaseItem, Question} from '../abstract/info';
import {commonImports} from "../app.imports";
import {LoadingService} from "../services/loading.service";

@Component({
    selector: 'ngx-knowledge-base',
    templateUrl: './knowledge-base.component.html',
    standalone: true,
  imports: [...commonImports],
    styleUrls: ['./knowledge-base.component.scss']
})
export class KnowledgeBaseComponent implements OnInit {
    public activeIndex: number = 0;

    public selectedQuestion?: Question;
    public item?: KnowledgeBaseItem;

    public knowledgeMap: CarouselItem = {
      title: 'title',
      description: 'shortDescription',
      icon: 'iconUrl',
      slug: 'slug',
      order: 'order'
    };
    public defaultPage = 'bot-launch';

    constructor(
        private readonly titleService: Title,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private el: ElementRef,
        private readonly infoService: InfoData,
        private readonly loadingService: LoadingService
    ) {
        this.titleService.setTitle('How to start | Burvix Traders');
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            const page = params['page'] || this.defaultPage;
            this.item = undefined;
            this.loadingService.openLoader();
            this.ref.detectChanges();
            this.loadInfoContent(page);
        });
    }

    loadInfoContent(slug: string) {
      this.infoService.getDataCollection<KnowledgeBaseItem[]>(
        'knowledge-bases',
        ['questions', 'questions.answers'],
        [{
          type: FilterType.eqi,
          field: 'slug',
          value: slug
        }],
        null,
        1,
        1).subscribe(res => {
          if (res.data.length === 0) {
            this.loadInfoContent(this.defaultPage);
            return;
          }

          this.item = res.data[0];

          this.loadingService.closeLoader();
          this.setActive(0);
          this.titleService.setTitle(`${this.item.attributes.title} | Burvix Traders`);
      });
    }

    setActive(index: number) {
      this.activeIndex = index;
      this.selectedQuestion = this.item!.attributes.questions[index];
      this.ref.detectChanges();

      const element = this.el.nativeElement.querySelector(`#question-header-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
}
