import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { map } from "rxjs/operators";
import { LoadingService } from "./loading.service";
import {combineLatest, of} from "rxjs";
import {UserAccessService} from "./auth.service";
import {isPlatformBrowser} from "@angular/common";

@Injectable()
export class UserAuthGuardService implements CanActivate {

  constructor(
    private accessChecker: UserAccessService,
    private loadingService: LoadingService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!isPlatformBrowser(this.platformId)) return of(false);

    let isUser = this.accessChecker.isInRole('user');
    let isRegistered = this.accessChecker.isRegistered();

    return combineLatest([isRegistered, isUser]).pipe(
      map(res => {
        if (res[0] && res[1]) {
          return res[1];
        }

        this.loadingService.setRedirectUrl(state.url);

        if (!res[1]) {
          this.router.navigate(['/login']);
        }
        else if (!res[0]) {
          this.router.navigate(['/register']);
        }

        return false;
      }),
    );
  }
}
