<ngx-personal-area-common>
  <div *ngIf="form" [formGroup]="form" style="max-width: 1200px">
    <div class="step">
      <div class="head-of-page">
        <h1><span class="step-number">STEP 1.</span> Choose bot {{botId}} runs</h1>
      </div>
      <div class="options">
        <ng-container *ngFor="let item of runs">
          <div [class.active]="selectedRun === item.id" [class.selectable]="canSelect(item.id)" class="option" (click)="selectRun(item.id)">
            <input type="radio" [value]="item.id" class="option-radio" formControlName="runId">
            <div>
              <p>{{item.startDate | date: "dd.MM.yyyy HH:mm"}} - {{item.endDate | date: "dd.MM.yyyy HH:mm"}}</p>
              <p>
                Profit - <strong>{{item.profit | ngxNumberWithCommas}}</strong>
                <span *ngIf="item.excludedOrders.length > 0">. Not included orders: {{item.excludedOrders.length}}</span>
              </p>
            </div>
            <button (click)="deleteRun(item)" class="delete-btn"><img width="30" height="30" ngSrc="/assets/images/cross.png" alt="cross"></button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="step">
      <div class="head-of-page">
        <h1><span class="step-number">STEP 2.</span> Pre-calculated data</h1>
      </div>
      <div class="option-text">
        <div class="text-info">
          <p>Selected invoice period: <strong>{{startDate | date: "dd.MM.yyyy HH:mm"}}</strong> - <strong>{{endDate | date: "dd.MM.yyyy HH:mm"}}</strong></p>
          <p>Total profit amount <strong>{{totalProfit | ngxNumberWithCommas}}</strong></p>
          <p>Referral balance: ${{referralBalance | ngxNumberWithCommas}}</p>
        </div>
      </div>
    </div>

    <div class="step">
      <div class="head-of-page">
        <h1><span class="step-number">STEP 3.</span> Set data</h1>
      </div>
    </div>

    <div class="api-input">
      <div class="api-input-inner">
        <div class="input-inner">
          <div>
            <h4>Total profit amount</h4>
            <input type="number" formControlName="totalAmount" required maxlength="250" step=".01">
          </div>
          <div>
            <h4>Amount to pay</h4>
            <input type="number" formControlName="amountToPay" required maxlength="250" step=".01">
          </div>
          <div>
            <h4>Referral balance</h4>
            <input type="number"
                   formControlName="referralBalance"
                   required
                   [maxlength]="250"
                   step=".01"
            >
          </div>
          <div>
            <h4>Amount to pay description</h4>
            <input type="text" formControlName="amountToPayDescription" required maxlength="250">
          </div>
          <div class="option-cbx">
            <input type="checkbox" formControlName="skipInvoicePayment" id="skipInvoicePayment-cbx">
            <label for="skipInvoicePayment-cbx">Skip invoice payment by user. Invoice will be confirmed immediately</label>
          </div>
        </div>
        <div class="text-api-input-inner">
        </div>
      </div>
    </div>

    <div class="portfolio">
      <div class="bnt-div">
        <button class="cnt-btn" [disabled]="!isFormValid" (click)="submitSettings()">Create invoice</button>
      </div>
    </div>
  </div>
</ngx-personal-area-common>
