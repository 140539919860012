import {of as observableOf, Observable} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  AssetBalance,
  AttentionInfo,
  BotAction,
  BotApiSettingInfo,
  BotInfo, BotOrder, BotPairLookup, BotRunInfo,
  BotShortInfo,
  BotStatistic,
  Contacts,
  FullBotStatistic,
  Invoice,
  InvoiceDates, Notification, Paging,
  PairComment,
  PaymentInfo,
  RecentUsers,
  ReferralInfo,
  User,
  UserData
} from '../abstract/users';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {finalize, map} from 'rxjs/operators';
import {SharedReplayRefresh} from "./shared.replay.refresh";

@Injectable()
export class UserService extends UserData {

  constructor(private http: HttpClient) {
    super();
  }

  private contacts: Contacts[] = [];
  private recentUsers: RecentUsers[]  = [];

  private userCache$: SharedReplayRefresh<User> | null = null;
  private fullStatCache$: SharedReplayRefresh<FullBotStatistic> | null = null;
  private botsCache$: SharedReplayRefresh<BotInfo[]> | null = null;

  clearCache() {
    this.userCache$ = null;
    this.fullStatCache$ = null;
    this.botsCache$ = null;
  }

  getMyUser(): Observable<User> {
    if (!this.userCache$) {
      this.userCache$ = new SharedReplayRefresh<User>(this.getMyUserInternal());
    }

    return this.userCache$.sharedReplayTimerRefresh(1, 300000);
  }

  private getMyUserInternal(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/user/me`);
  }

  updateUserProfile(account: any) {
    return this.http.post(`${environment.apiUrl}/user/me`, account)
      .pipe(finalize(() => this.clearCache()));
  }

  getUsers(): Observable<any> {
    return this.http.get<User[]>(`${environment.apiUrl}/admin/users`);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }

  getMyProfit(): Observable<FullBotStatistic> {
    if (!this.fullStatCache$) {
      this.fullStatCache$ = new SharedReplayRefresh<FullBotStatistic>(this.getMyProfitInternal());
    }

    return this.fullStatCache$.sharedReplayTimerRefresh(1, 20000);
  }

  private getMyProfitInternal(): Observable<FullBotStatistic> {
    return this.http.get<FullBotStatistic>(`${environment.apiUrl}/user/me/statistic`);
  }

  getBotProfit(botId: string): Observable<FullBotStatistic> {
    return this.http.get<FullBotStatistic>(`${environment.apiUrl}/user/me/${botId}/statistic/v2`);
  }

  getBotProfitForDateRange(start: string, end: string): Observable<{ [key: string]: BotStatistic[]; }> {
    return this.http.get<{ [key: string]: BotStatistic[]; }>(`${environment.apiUrl}/user/me/statistic/period?begin=${start}&end=${end}`);
  }

  getInvoices(userId: any): Observable<InvoiceDates> {
    return this.http.get<InvoiceDates>(`${environment.apiUrl}/admin/users/${userId}/orders`);
  }

  getBots(): Observable<BotInfo[]> {
    if (!this.botsCache$) {
      this.botsCache$ = new SharedReplayRefresh<BotInfo[]>(this.getBotsInternal());
    }

    return this.botsCache$.sharedReplayTimerRefresh(1, 20000);
  }

  private getBotsInternal(): Observable<BotInfo[]> {
    return this.http.get<BotInfo[]>(`${environment.apiUrl}/user/me/bots`);
  }

  getBotOrders(botId: string, take: number, skip: number): Observable<Paging<BotOrder>> {
    return this.http.get<Paging<BotOrder>>(
      `${environment.apiUrl}/exchange/${botId}/orders?limit=${take}&offset=${skip}`
    );
  }

  getNotifications(take: number, skip: number): Observable<Paging<Notification>> {
    return this.http.get<Paging<Notification>>(`${environment.apiUrl}/user/me/notifications?limit=${take}&offset=${skip}`);
  }

  getNotificationsByType(type: string, take: number, skip: number): Observable<Paging<Notification>> {
    return this.http.get<Paging<Notification>>(`${environment.apiUrl}/user/me/notifications/${type}?limit=${take}&offset=${skip}`);
  }

  getAttentionInfo(): Observable<AttentionInfo> {
    return this.http.get<AttentionInfo>(`${environment.apiUrl}/user/me/attention`);
  }
  getOrdersFile(botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Blob> {
    let url = `${environment.apiUrl}/user/me/orders/csv`;

    const params = {
        botId: botId || '',
        exchangeSettingId: exchangeSettingId !== null ? exchangeSettingId.toString() : '',
        isWithoutInvoice: isWithoutInvoice !== null ? isWithoutInvoice.toString() : '',
        startDate: startDate || '',
        endDate: endDate || ''
    };

    return this.http.post(url, null, {
        params: params,
        responseType: 'blob'
    });
  }
  getInvoice(id: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.apiUrl}/user/me/invoices/${id}`);
  }
  getNotificationById(id: number): Observable<Notification> {
    return this.http.get<Notification>(`${environment.apiUrl}/user/me/notification/${id}`);
  }

  getPairComments(): Observable<{ [key: number]: PairComment[]; }> {
    return this.http.get<{ [key: number]: PairComment[] }>(`${environment.apiUrl}/user/me/balance/comments`);
  }

  getExchangeBalance(id: number): Observable<AssetBalance[]> {
    return this.http.get<AssetBalance[]>(`${environment.apiUrl}/user/me/balance/${id}`);
  }

  getPayments(take: number, skip: number, exchangeId: number, botId: string): Observable<Paging<PaymentInfo>> {
    let url = `${environment.apiUrl}/user/me/payments?limit=${take}&offset=${skip}`;

    if (exchangeId) {
      url += `&exchangeSettingId=${exchangeId}`;
    }

    if (botId) {
      url += `&botId=${botId}`;
    }

    return this.http.get<Paging<PaymentInfo>>(url);
  }

  getShortBots(resetCache: boolean): Observable<BotShortInfo[]> {
    const additionalQuery = resetCache
      ? `?q=${Date.now()}`
      : '';
    return this.http.get<BotShortInfo[]>(`${environment.apiUrl}/user/me/bots/short${additionalQuery}`);
  }

  getBotShortInfo(addDefaultValues: boolean): Observable<BotPairLookup[]> {
    return this.getShortBots(false)
      .pipe(map(b => this.remapBotShortInfo(b, addDefaultValues)));
  }
  getOrders(take: number, skip: number, botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Paging<BotOrder>> {
    let url = `${environment.apiUrl}/exchange/orders/filtered?limit=${take}&offset=${skip}`;

    if (botId) {
      url += `&botId=${botId}`;
    }

    if (exchangeSettingId) {
      url += `&exchangeSettingId=${exchangeSettingId}`;
    }

    if (isWithoutInvoice) {
      url += `&isWithoutInvoice=${isWithoutInvoice}`;
    }

    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    if (endDate) {
      url += `&startDate=${endDate}`;
    }

    return this.http.get<Paging<BotOrder>>(url);
  }
  getOpenOrders(botId: string): Observable<BotOrder[]> {
    return this.http.get<BotOrder[]>(`${environment.apiUrl}/exchange/orders/opened?botId=${botId}`);
  }

  getReferralInfo(): Observable<ReferralInfo> {
    return this.http.get<ReferralInfo>(`${environment.apiUrl}/user/me/referral`);
  }

  sendBotAction(botId: string, action: BotAction): Observable<any> {
    return this.http.post(`${environment.apiUrl}/user/me/bot/send-action`, {
      botId,
      action: BotAction[action]
    });
  }

  getBotLaunchSettings(invoiceId: number): Observable<BotApiSettingInfo> {
    return this.http.get<BotApiSettingInfo>(`${environment.apiUrl}/user/me/bot/continue/settings/${invoiceId}`);
  }
  launchNewBot(settingId: number, amount: number, botCurrencyId: number) {
    return this.http.post(`${environment.apiUrl}/user/me/bot/continue/${settingId}`, {
      amount,
      botCurrencyId
    });
  }

  getBalanceSnapshot(settingId: number, year:number|null) {
    let url = `${environment.apiUrl}/user/me/exchange/${settingId}/balance`;
    if(year) url += `?year=${year}`;

    return this.http.get<BotStatistic[]>(url);
  }

  getUsersOrdersFile(email: string, botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Blob> {
    let url = `${environment.apiUrl}/admin/users/${email}/orders/csv`;

    const params = {
        botId: botId || '',
        exchangeSettingId: exchangeSettingId !== null ? exchangeSettingId.toString() : '',
        isWithoutInvoice: isWithoutInvoice !== null ? isWithoutInvoice.toString() : '',
        startDate: startDate || '',
        endDate: endDate || ''
    };

    return this.http.post(url, null, {
        params: params,
        responseType: 'blob'
    });
  }

  deleteHistoryOrdersById(id: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/admin/users/orders/${id}/delete`, {});
  }

  getUserProfit(userId: string): Observable<FullBotStatistic> {
    return this.http.get<FullBotStatistic>(`${environment.apiUrl}/admin/users/${userId}/statistic/v2`);
  }
  getUserBots(userId: string): Observable<BotInfo[]> {
    return this.http.get<BotInfo[]>(`${environment.apiUrl}/admin/users/${userId}/bots`);
  }
  getUserBotOrders(userId: string, botId: string, take: number, skip: number): Observable<Paging<BotOrder>> {
    return this.http.get<Paging<BotOrder>>(`${environment.apiUrl}/admin/users/${userId}/${botId}/orders?limit=${take}&offset=${skip}`);
  }
  getUserBotProfit(userId: string, botId: string): Observable<FullBotStatistic> {
    return this.http.get<FullBotStatistic>(`${environment.apiUrl}/admin/users/${userId}/${botId}/statistic/v2`);
  }

  getUserBotProfitForDateRange(userId: string, start: string, end: string): Observable<{ [key: string]: BotStatistic[]; }> {
    return this.http.get<{ [key: string]: BotStatistic[]; }>(`${environment.apiUrl}/admin/users/${userId}/statistic/period?begin=${start}&end=${end}`);
  }

  getUserNotifications(userId: string, take: number, skip: number): Observable<Paging<Notification>> {
    return this.http.get<Paging<Notification>>(`${environment.apiUrl}/admin/users/${userId}/notifications?limit=${take}&offset=${skip}`);
  }
  getUserNotificationsByType(userId: string, type: string, take: number, skip: number): Observable<Paging<Notification>> {
    return this.http.get<Paging<Notification>>(`${environment.apiUrl}/admin/users/${userId}/notifications/${type}?limit=${take}&offset=${skip}`);
  }

  getUserNotificationById(userId: string, id: number): Observable<Notification> {
    return this.http.get<Notification>(`${environment.apiUrl}/admin/users/${userId}/notification/${id}`);
  }

  getUserBotRuns(botId: string): Observable<{[key: string]: BotRunInfo[]}> {
    return this.http.get<{[key: string]: BotRunInfo[]}>(`${environment.apiUrl}/admin/exchange/bots?botId=${botId}`);
  }

  createInvoice(userId: any, runs: number[], totalAmount: number, toPayAmount: number, toPayDescription: string, approveInvoice: boolean): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/admin/users/invoice`, {
      email: userId,
      totalAmount: totalAmount,
      amountToPay: toPayAmount,
      message: toPayDescription,
      amountToPayDescription: toPayDescription,
      approveInvoice: approveInvoice,
      runs: runs
    });
  }

  setBotComment(userId: any, botId: any, comment: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/admin/bot/comment`, {
      email: userId,
      botId: botId,
      comment: comment
    });
  }

  getUserPayments(userId: string, take: number, skip: number, exchangeId: number, botId: string): Observable<Paging<PaymentInfo>> {
    let url = `${environment.apiUrl}/admin/users/${userId}/payments?limit=${take}&offset=${skip}`;

    if (exchangeId) {
      url += `&exchangeSettingId=${exchangeId}`;
    }

    if (botId) {
      url += `&botId=${botId}`;
    }

    return this.http.get<Paging<PaymentInfo>>(url);
  }

  getUserShortBots(userId: any, resetCache: boolean): Observable<BotShortInfo[]> {
    const additionalQuery = resetCache
      ? `?q=${Date.now()}`
      : '';
    return this.http.get<BotShortInfo[]>(`${environment.apiUrl}/admin/users/${userId}/bots/short${additionalQuery}`);
  }

  getUserBotShortInfo(userId: any, addDefaultValues: boolean): Observable<BotPairLookup[]> {
    return this.getUserShortBots(userId, false)
      .pipe(map(b => this.remapBotShortInfo(b, addDefaultValues)));
  }

  getUserOrders(userId: any, take: number, skip: number, botId: string, exchangeSettingId: number, isWithoutInvoice: boolean, startDate: string, endDate: string): Observable<Paging<BotOrder>> {
    let url = `${environment.apiUrl}/admin/users/${userId}/orders/filtered?limit=${take}&offset=${skip}`;

    if (botId) {
      url += `&botId=${botId}`;
    }

    if (exchangeSettingId) {
      url += `&exchangeSettingId=${exchangeSettingId}`;
    }

    if (isWithoutInvoice) {
      url += `&isWithoutInvoice=${isWithoutInvoice}`;
    }

    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    if (endDate) {
      url += `&startDate=${endDate}`;
    }

    return this.http.get<Paging<BotOrder>>(url);
  }
  getUserOpenOrders(userId: any, botId: string): Observable<BotOrder[]> {
    return this.http.get<BotOrder[]>(`${environment.apiUrl}/admin/users/${userId}/orders/opened?botId=${botId}`);
  }

  getUserReferralInfo(userId: any): Observable<ReferralInfo> {
    return this.http.get<ReferralInfo>(`${environment.apiUrl}/admin/users/${userId}/referral`);
  }

  getUserPairComments(userId: any): Observable<{ [key: number]: PairComment[]; }> {
    return this.http.get<{ [key: number]: PairComment[] }>(`${environment.apiUrl}/admin/users/${userId}/balance/comments`);
  }

  getUserExchangeBalance(userId: any, id: number): Observable<AssetBalance[]> {
    return this.http.get<AssetBalance[]>(`${environment.apiUrl}/admin/users/${userId}/balance/${id}`);
  }

  completePayment(invoiceId: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/admin/invoices/${invoiceId}/complete`, {});
  }

  sendUserBotAction(userId: any, botId: string, action: BotAction) {
    return this.http.post(`${environment.apiUrl}/admin/users/${userId}/bot/send-action`, {
      botId,
      action: BotAction[action]
    });
  }

  deleteUserBotRun(userId: any, botRunId: number) {
    return this.http.post(`${environment.apiUrl}/admin/users/${userId}/bots/runs/${botRunId}/delete`, {});
  }

  getUserBalanceSnapshot(userId: any, settingId: number, year:number|null) {
    let url = `${environment.apiUrl}/admin/users/${userId}/exchange/${settingId}/balance`;
    if(year) url += `?year=${year}`;

    return this.http.get<BotStatistic[]>(url);
  }

  remapBotShortInfo(bots: BotShortInfo[], addDefaultValues: boolean): BotPairLookup[] {
    let exchanges = Object.keys(bots.reduce((prev, curr) => {
      if (!prev[curr.exchangeSettingId]) {
        prev[curr.exchangeSettingId] = true;
      }
      return prev;
    }, {} as {[key: number]: boolean}));

    const pairs = new Array<BotPairLookup>();
    const defaultPair = {
      botId: '',
      baseCoin: null,
      cryptoCoin: 'All'
    };
    if (addDefaultValues) {
      pairs.push({
        name: 'All',
        exchangeId: 0,
        pairs: [defaultPair]
      });
    }

    for (let i = 0; i < exchanges.length; i++) {
      let p = bots.filter(x => x.exchangeSettingId === parseInt(exchanges[i], 10));
      let mp = p.map(x => {
        return {
          botId: x.botId, baseCoin: x.baseCoin, cryptoCoin: x.cryptoCoin
        };
      });
      pairs.push({
        name: p[0].exchangeName || p[0].exchangeType,
        exchangeId: p[0].exchangeSettingId,
        pairs: addDefaultValues
          ? [defaultPair, ...mp]
          : mp
      });
    }

    return pairs;
  }
}
