import {afterNextRender, ChangeDetectorRef, Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {ExchangeItem, InfoData} from '../abstract/info';
import {commonImports} from "../app.imports";
import {combineLatest} from "rxjs";
import {finalize} from "rxjs/operators";
import {LoadingService} from "../services/loading.service";
import {BotPairLookup, BotShortInfo, PaymentInfo, UserData} from "../abstract/users";
import {BotDashboardComponent} from "../bot-dashboard/bot-dashboard.component";

@Component({
    selector: 'ngx-main-page',
    templateUrl: './main-page.component.html',
    standalone: true,
    imports: [...commonImports, BotDashboardComponent],
    styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent {

    userId: any;
    selectedExchange: BotPairLookup|null = null;
    options: {[key: string]: any} = {};

    forceApi = false;
    requestConnect = false;
    userSelectedExchange: string = 'binance';
    allBots: {[key: string]: BotShortInfo[]} = {};
    allInvoices: {[key: string]: PaymentInfo[]} = {};
    isLoaded = false;

    get botIds() {
      return this.allBots[this.userSelectedExchange] || [];
    }

    get exchangeInvoices() {
      return this.allInvoices[this.userSelectedExchange] || [];
    }

    constructor(
        private readonly titleService: Title,
        private router: Router,
        private readonly route: ActivatedRoute,
        private readonly ref: ChangeDetectorRef,
        private readonly infoService: InfoData,
        private readonly userService: UserData,
        private readonly loadingService: LoadingService
    ) {
      this.titleService.setTitle('My account | Burvix Traders');
      afterNextRender(() => {
        this.route.queryParams.subscribe(res => {
          this.forceApi = res['forceApi'] === 'true';
          this.requestConnect = res['successConnect'] === 'true';
        });
        this.route.paramMap.subscribe(params => {
          this.userId = params.get('userId');
          const urlExchange = params.get('exchange');

          this.loadData(urlExchange || '');
        });
      });
    }

    exchanges = [
        {
          name: 'Binance',
          icon: '/assets/images/binance.svg',
          link: 'binance.com',
          isActive: true,
          notification: false
        }
    ];

    setActive(selectedItem: any) {
      if(selectedItem.name.toLowerCase() == this.userSelectedExchange) return;
      let routes = ['my', selectedItem.name.toLowerCase()];

      if(this.userId) {
        routes = ['awesome-admin', this.userId, ...routes];
      }

      routes[0] = '/' + routes[0];

      this.isLoaded = false;
      this.router.navigate(routes);
    }

  loadData(selectedExchange: string): void {
    this.loadingService.openLoader();

    const bots = this.userId
      ? this.userService.getUserShortBots(this.userId, true)
      : this.userService.getShortBots(true);

    const exchanges = this.infoService.getDataCollection<ExchangeItem[]>('exchanges', [], [], ['order'], null, null);
    const invoices = this.userService.getAttentionInfo();

    combineLatest([bots, exchanges, invoices]).pipe(
      finalize(() => {
        this.isLoaded = true;
        this.loadingService.closeLoader();
        this.ref.markForCheck();
      })
    ).subscribe(res => {
      this.allInvoices = this.userId
        ? {}
        : res[2].invoices.reduce((res, i) => {
          let exchangeName = i.exchangeName.toLowerCase();
          if(!res[exchangeName]) res[exchangeName] = [];

          res[exchangeName].push(i);

          return res;
        }, {} as {[key: string]:PaymentInfo[]})

      this.allBots = res[0].reduce((bs, b) => {
        let exchangeName = b.exchangeType.toLowerCase();
        if(!bs[exchangeName]) bs[exchangeName] = [];
        bs[exchangeName].push(b);

        return bs;
      }, {} as {[key: string]:BotShortInfo[]});
      const es = res[1].data.filter(e => e.attributes.name.toLowerCase() === selectedExchange.toLowerCase() || (!selectedExchange && this.allBots[e.attributes.name.toLowerCase()]));

      this.userSelectedExchange = es.length > 0
        ? es[0].attributes.name.toLowerCase()
        : 'binance';

      this.exchanges = res[1].data.map(item => ({
        name: item.attributes.name,
        icon: item.attributes.alternativeIcon,
        link: item.attributes.link,
        isActive: item.attributes.name.toLowerCase() == this.userSelectedExchange,
        notification: !!this.allInvoices[item.attributes.name.toLowerCase()],
      }));
    });
  }
}
