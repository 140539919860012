@defer (when item){
  <div class="header-block" *ngIf="item">
    <div class="header-block-inner">
      <div class="started-header">
        <div class="header">
          <div class="item left">
            <img [ngSrc]="item!.attributes.iconUrl" alt="icon" width="80" height="80">
          </div>
          <h2 class="gradient-text">{{ item!.attributes.title }}</h2>
          <div class="item">
            <img [ngSrc]="item!.attributes.iconUrl" alt="icon" width="80" height="80">
          </div>
        </div>
        <ngx-text-block className="started-header-text" [blocks]="item!.attributes.description"></ngx-text-block>
      </div>
      <img class="grid" ngSrc="/assets/images/grid.png" alt="grid" width="1662" height="819">
    </div>

    <div class="main-info-text">
      <div class="main-info-text-menu mob">
        @for (question of item!.attributes.questions; track question; let i = $index) {
          <ngx-text-block id="question-header-{{ i }}" (click)="setActive(i)" [ngClass]="{'active': i === activeIndex}" [blocks]="question.name" class="mob-header-block"></ngx-text-block>
          @if(i == activeIndex){
            <div class="main-info-text-body mob">
              @if(selectedQuestion) {
                <div class="block">
                  <p class="marker">{{ selectedQuestion.tag }}</p>
                  <div class="content">
                    @for(block of selectedQuestion.answers; track block) {
                      <div class="blocks">
                        <h3>{{ block.header }}</h3>
                        <ngx-text-block className="body" [blocks]="block.body"></ngx-text-block>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }
        }
      </div>

      <div class="main-info-text-body">
        @if(selectedQuestion) {
          <div class="block">
            <p class="marker">{{ selectedQuestion.tag }}</p>
            <div class="content">
              @for(block of selectedQuestion.answers; track block) {
                <div class="blocks">
                  <h3>{{ block.header }}</h3>
                  <ngx-text-block className="body" [blocks]="block.body"></ngx-text-block>
                </div>
              }
            </div>
          </div>
        }
      </div>

    </div>

    <ngx-carousel color="rgba(255, 255, 255, 0.08)" entity="knowledge-bases" [itemMap]="knowledgeMap" [displayHeader]="false"></ngx-carousel>
  </div>
}
