import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import {isPlatformBrowser} from "@angular/common";
import {of} from "rxjs";
import {UserAccessService} from "./auth.service";

@Injectable()
export class GuestAuthGuardService implements CanActivate {

  constructor(
    private accessChecker: UserAccessService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!isPlatformBrowser(this.platformId)) return of(false);
    return this.accessChecker.isInRole('user').pipe(
      map(res => {
        if (res) {
          this.router.navigate(['/my']);
          return false;
        }

        return true;
      }),
    );
  }
}
