<div class="term">
  @defer (when item) {
    <div class="main-term">
      <div class="head-of-page">
        <p class="data-marker">{{item!.attributes.tag}}</p>
        <h1 class="gradient-text">{{item!.attributes.title}}</h1>
        <div class="shadow"></div>
        <div class="line-shadow"></div>
      </div>
      <div class="terms">
        @for(block of item!.attributes.blocks; track block) {
          @switch (block.__component){
            @case ('components.header') {
              <div class="usual-block" [class.first-block]="block===item!.attributes.blocks[0]">
                @if(block.title) {
                  <h2 class="gradient-text">{{block.title}}</h2>
                }

                <ngx-text-block className="usual-block" [blocks]="asBlockWithHeaderComponent(block).body" ></ngx-text-block>

                @if(asBlockWithHeaderComponent(block).desclaimer) {
                  <p class="attation-text">{{asBlockWithHeaderComponent(block).desclaimer}}</p>
                }
              </div>
            }
            @case ('components.definitions-block') {
              <div class="definitions-block">
                <h2 *ngIf="block.title" class="gradient-text">{{block.title}}</h2>
                <ul>
                  @for(item of asDefinitionsBlockComponent(block).rows; track item) {
                  }
                  <li *ngFor="let item of $any(block).rows">
                    <div class="li-text-inner">
                      <p class="header">{{item.title}}</p>
                      <ngx-text-block className="body" [blocks]="item.body" ></ngx-text-block>
                    </div>
                  </li>
                </ul>
                @if(asDefinitionsBlockComponent(block).text) {
                  <ngx-text-block className="usual-block" [blocks]="asDefinitionsBlockComponent(block).text" ></ngx-text-block>
                }
              </div>
            }
          }
        }
        <ng-content></ng-content>
      </div>
    </div>
  }
</div>
