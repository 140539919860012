import {afterNextRender, Component, inject, Input} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {commonImports} from "../../app.imports";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {User, UserData} from "../../abstract/users";
import {MenuItem, UserAccessService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  imports: [...commonImports],
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  standalone: true
})
export class HeaderComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  userPictureOnly: boolean = false;
  user?: User;
  userImageUrl: string = '';
  isOpen = false;
  currentTheme = 'default';

  @Input()
  unreadCount: number = 1;

  userMenu: {
    title: string
    data: {
      id: string
    }
  }[] = [];

  userId: string | null = null;

  private document: Document = inject(DOCUMENT);
  private accessService: UserAccessService = inject(UserAccessService);

  bottomMenu = [
    {
      name: 'Settings',
      icon: '/assets/images/settings.svg',
      role: 'user',
      link: '/my/settings',
      action: () => this.redirectToSettings(),
      class: ''
    },
    {
      name: 'Log Out',
      icon: '/assets/images/log-out.svg',
      role: 'user',
      link: '/',
      action: () => this.logout(),
      class: ''
    }
  ]

  getMenu() {
    return [
      {
        name: 'Overview',
        icon: '/assets/images/overview.svg',
        role: 'user',
        link: `/my`,
        class: ''
      },
      {
        name: 'Referral Program',
        icon: '/assets/images/referral-program.svg',
        role: 'user',
        link: `/my/referral-program`,
        class: ''
      },
      {
        name: 'Payments',
        icon: '/assets/images/payments.svg',
        role: 'user',
        link: `/my/payments`,
        class: ''
      }
    ]
  }

  constructor(
    private userService: UserData,
    private router: Router,
    private readonly route: ActivatedRoute,
  ) {
    afterNextRender(()=> {
      this.menu = this.getMenu();
      this.accessService.getMenu().subscribe(res => {
        this.menu = res;
      })
      this.accessService.getUser().subscribe(res => {
        const hasUser = this.user !== undefined;
        if(!res) {
          this.user = undefined;
          this.userImageUrl = '';
          if(hasUser) {
            this.router.navigate(['/login']);
          }
        } else {
          this.user = res;
          this.userImageUrl = res.photoUrl;
        }

      });

      this.oidcSecurityService.isAuthenticated$.subscribe(res => {
        if(this.user && !res.isAuthenticated)
          this.accessService.setUser(null);
      })

      this.oidcSecurityService.checkAuth()
        .subscribe((loginResponse) => {
          if(loginResponse.isAuthenticated) {
            this.userService.getMyUser()
              .subscribe(res => {
                this.user = res;
                this.userImageUrl = res.photoUrl;
                this.accessService.setUser(res);
              });
          } else {
            this.accessService.setUser(null);
          }
        });
    });
  }

  menu:MenuItem[] = [];


  //token: NbAuthOAuth2Token;

  isAuthenticated: boolean = false;

  /*constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private accessChecker: NbAccessChecker,
              private authService: NbAuthService)
  {
    this.router.events.subscribe(() => {
      this.checkRoute();
    });
  }

  ngOnInit() {

    this.accessChecker.isGranted('edit', 'user')
      .subscribe(res => this.isAuthenticated = res);

    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemSelect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: { tag: string, item: any }) => {
        if (document.documentElement.clientWidth < xl){
          this.sidebarService.collapse('menu-sidebar');
        }
    });

    this.menuService.onItemClick().pipe(
      filter(({ tag }) => tag === 'profile'),
    ).subscribe((menuBag) => {
      switch(menuBag.item.data.id) {
        case 'profit':
          this.goToProfitPage();
          break;
          case 'profile':
            this.goToProfilePage();
            break;
          case 'admin':
            this.goToAdminPage();
            break;
        case 'assets':
          this.goToAssetsPage();
          break;
        case 'orders':
          this.goToOrdersPage();
          break;
        case 'payments':
          this.goToPaymentsPage();
          break;
        case 'pnl':
            this.goToPnlPage();
            break;
        case 'notification':
            this.goToNotificationPage();
            break;
        case 'referral':
            this.goToReferralPage();
            break;
        case 'logout':
          this.logout();
          break;
      }
    });

    this.authService
      .onTokenChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthToken) => {
        if (token && token.isValid()) {
          this.token = token as NbAuthOAuth2Token;
          this.userService.getMyUser()
            .pipe(takeUntil(this.destroy$))
            .subscribe((user: User) => {
              this.user = user;
              if (user && user.roles.includes('admin')) {
                this.userMenu = [
                  { title: 'Profile', data: {id: 'profile'} },
                  { title: 'Statistic', data: {id: 'pnl'} },
                  { title: 'Orders', data: {id: 'orders'} },
                  { title: 'Assets', data: {id: 'assets'} },
                  { title: 'Payments', data: {id: 'payments'} },
                  { title: 'Notifications', data: {id: 'notification'} },
                  { title: 'Referral program', data: {id: 'referral'} },
                  { title: 'Log out', data: {id: 'logout'} },
                  { title: 'Admin', data: {id: 'admin'} }
                ];
              } else {
                this.userMenu = [
                  { title: 'Profile', data: {id: 'profile'} },
                  { title: 'Statistic', data: {id: 'pnl'} },
                  { title: 'Orders', data: {id: 'orders'} },
                  { title: 'Assets', data: {id: 'assets'} },
                  { title: 'Payments', data: {id: 'payments'} },
                  { title: 'Notifications', data: {id: 'notification'} },
                  { title: 'Referral program', data: {id: 'referral'} },
                  { title: 'Log out', data: {id: 'logout'} }
                ];
              }
            });
        } else {
          this.token = null;
          this.isAuthenticated = false;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  checkRoute(){
    if (this.router.url === '/pages/dashboard/login') {
      this.showNavMenu = false;
    }
    else{
      this.showNavMenu = true;
    }
  }


  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  goToProfitPage() {
    this.router.navigate(['/pages/dashboard/bots']);
  }

  goToAssetsPage() {
    this.router.navigate(['/pages/dashboard/assets']);
  }

  goToOrdersPage(){
    this.router.navigate(['/pages/dashboard/orders']);
  }

  goToPaymentsPage(){
    this.router.navigate(['/pages/dashboard/payments']);
  }

  goToPnlPage(){
    this.router.navigate(['/pages/dashboard/statistic']);
  }

  goToAdminPage() {
    this.router.navigate(['/pages/dashboard/awesome-admin']);
  }

  goToProfilePage() {
    this.router.navigate(['/pages/dashboard/profile']);
  }

  goToNotificationPage() {
    this.router.navigate(['/pages/dashboard/notifications']);
  }

  goToReferralPage() {
    this.router.navigate(['/pages/dashboard/referral-program-link']);
  }

  login() {
    this.logout();

    this.authService
      .authenticate('google')
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResult: NbAuthResult) => {});
  }

  logout() {
    this.isAuthenticated = false;
    this.userService.clearCache();
    this.authService
      .logout('google')
      .pipe(takeUntil(this.destroy$))
      .subscribe((authResult: NbAuthResult) => {
        window.location.reload();
      });
  }*/

  scrollToElement(elementId: string) {
    const element = this.document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  hasAccess(role: string) {
    return this.accessService.isInRole(role);
  }

  isActive(link: string): boolean {
    return this.router.url === link;
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  login(){
    this.accessService.authorize();
  }

  logout() {
    this.accessService.getUser();
    this.oidcSecurityService.logoff().subscribe({
      next: () => {
        this.accessService.setUser(null);

        localStorage.clear();
        sessionStorage.clear();

        window.location.href = '/';
      },
      error: (err) => console.error('Logout error:', err)
    });
  }

  closeMenu(): void {
    this.isOpen = false;
  }

  redirectToSettings(){
    this.router.navigate(['/my/settings']);
  }
}
