import { afterNextRender, AfterViewInit, Component, inject } from '@angular/core';
import { commonImports } from '../app.imports';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { User, UserData } from '../abstract/users';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../services/loading.service';
import { combineLatest, finalize } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopUpComponent } from '../common/pop-up/pop-up.component';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [...commonImports],
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements AfterViewInit{
  private readonly oidcSecurityService = inject(OidcSecurityService);
  user?: User;
  userId!: string | null;
  link: string = "https://burvix.io?refCode=";
  form: FormGroup;
  userImageUrl: string = '';

  // Store the initial values to check for changes
  origValue = {
    phone: '',
    telegramAccount: '',
    whatsApp: ''
  };
  readonly dialog = inject(MatDialog);

  constructor(
    private userService: UserData,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder
  ) {
    // Initialize the form with empty values
    this.form = this.formBuilder.group({
      phone: [''],
      telegramAccount: [''],
      whatsApp: ['']
    });

    // After render, check authentication and load user data
    afterNextRender(() => {
      window.scrollTo(0, 0);
      this.oidcSecurityService.checkAuth().subscribe((loginResponse: LoginResponse) => {
        if (loginResponse.isAuthenticated) {
          this.userService.getMyUser().subscribe(res => {
            this.user = res;
            this.userImageUrl = res.photoUrl;
            this.origValue = {
              phone: res.phone || '',
              telegramAccount: res.telegramAccount || '',
              whatsApp: res.whatsApp || ''
            };
            this.form.setValue(this.origValue);
          });
        }
      });
    });

    // Load data based on the route parameters
    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.loadData();
    });
  }

  ngAfterViewInit(): void {
    this.loadingService.openLoader();
    const user = this.userService.getMyUser();
    combineLatest([user]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      this.user = res[0];
      this.origValue = {
        phone: res[0].phone || '',
        telegramAccount: res[0].telegramAccount || '',
        whatsApp: res[0].whatsApp || ''
      };
      this.form.setValue(this.origValue);
    });
  }

  // Check if any values have changed in the form
  get canSubmit() {
    return this.origValue.phone != this.form.value.phone
      || this.origValue.telegramAccount != this.form.value.telegramAccount
      || this.origValue.whatsApp != this.form.value.whatsApp;
  }

  // Load additional data (e.g., referral info)
  loadData() {
    this.loadingService.openLoader();

    const referral = this.userId
      ? this.userService.getUserReferralInfo(this.userId)
      : this.userService.getReferralInfo();

    combineLatest([referral])
      .pipe(finalize(() => this.loadingService.closeLoader()))
      .subscribe(res => {
        this.link += res[0].referralId;
      });
  }

  // Save form data if there are changes
  save() {
    if (this.canSubmit) {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          title: 'Save changes?',
          message: 'Do you want to save changes and return to “settings” ?',
          cancelButtonText: 'Cancel',
          saveButtonText: 'Save',
        }
      });

      dialogRef.afterClosed().subscribe((action) => {
        if (action) {
          this.loadingService.openLoader();
          this.userService.updateUserProfile(this.form.value)
            .pipe(finalize(() => this.loadingService.closeLoader()))
            .subscribe(() => {
              this.origValue = { ...this.form.value };
            });
          }
        });
    }
  }

  // Method to contact support
  contact() {
    window.open('https://t.me/Burvix_support', '_blank');
  }
}
