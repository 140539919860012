import {afterNextRender, Component, OnDestroy} from '@angular/core';
import {commonImports} from "../app.imports";
import {Router} from "@angular/router";

@Component({
  selector: 'ngx-callback',
  styleUrls: ['./callback.component.scss'],
  templateUrl: './callback.component.html',
  standalone: true,
  imports: [...commonImports]
})
export class CallbackComponent implements OnDestroy {

  private timeoutId?:NodeJS.Timeout;

  constructor(private router: Router) {
    afterNextRender( () => {
      this.timeoutId = setTimeout(() => {
        this.router.navigate(['/my']);
      }, 7000);
    });

  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
