<ngx-personal-area-common>
    @defer(when bots != null)
    {
        <div class="table-block">
            <div class="header-table">
                <div class="header-back">
                    <span (click)="goBack()"><img ngSrc="/assets/images/back.svg" alt="back" width="16" height="14"></span>
                    <h4>Recent Actions</h4>
                </div>

                <div class="params-block">
                    <div class="dropdown-blocks">

                      @if(showHistoryTable) {
                        <app-dropdown
                          [options]="periodOptions"
                          (selected)="historyPeriodFilterChanged($event)"
                        ></app-dropdown>
                      }

                        <app-dropdown
                            [options]="botId"
                            (selected)="historyFilterChanged($event)"
                        ></app-dropdown>
                    </div>

                    <div class="filter-block">
                      <app-filter>
                        @if(showHistoryTable) {
                          <app-dropdown-slider
                            [options]="periodOptions"
                            (selected)="historyPeriodFilterChanged($event)"
                          ></app-dropdown-slider>
                        }

                        <app-dropdown-slider
                            [options]="botId"
                            (selected)="historyFilterChanged($event)"
                        ></app-dropdown-slider>
                      </app-filter>
                    </div>

                    <button class="download-btn" (click)="downloadCsv()">
                        <span><img ngSrc="/assets/images/download1.svg" alt="download1" width="18" height="18"></span>
                        Download csv
                    </button>

                    <div class="switcher">
                        <p [ngClass]="{'active': isActiveTab == 0}" (click)="setActiveTab(0)">Trade History</p>
                        <p [ngClass]="{'active': isActiveTab == 1}" (click)="setActiveTab(1)">Open Orders</p>
                    </div>
                </div>
            </div>

            <app-recent-actions
                [count]="10"
                [actions]="showHistoryTable ? orders : openOrders"
                [isAdmin]="showHistoryTable && !!userId"
            ></app-recent-actions>
        </div>
    }

    @if(totalPages > 1){
        <ngx-pagination
            style="display: block; margin-bottom: 40px;"
            [pagesCount]="totalPages"
            (pageSelected)="loadPage($event)">
        </ngx-pagination>
    }
</ngx-personal-area-common>

