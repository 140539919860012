import {ApplicationConfig, ErrorHandler, provideZoneChangeDetection} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {UserData} from "./abstract/users";
import {ExchangeData} from "./abstract/exchange";
import {LookupData} from "./abstract/lookup";
import {InfoData} from "./abstract/info";
import {InfoDataService} from "./services/info.data.service";
import {LookupService} from "./services/lookup.service";
import {ExchangeService} from "./services/exchange.service";
import {UserService} from "./services/user.service";
import {AnalyticsService} from "./@core/utils/analytics.service";
import {SeoService} from "./@core/utils/seo.service";
import {authConfig} from './auth/auth.config';
import {AbstractSecurityStorage, DefaultLocalStorageService, provideAuth} from 'angular-auth-oidc-client';
import {accessTokenInterceptor, UserAccessService} from "./services/auth.service";
import {UserAuthGuardService} from "./services/user.auth.guard.service";
import {provideEcharts} from "ngx-echarts";
import {GuestAuthGuardService} from "./services/guest.auth.guard.service";
import {AdminAuthGuardService} from "./services/admin.auth.guard.service";
import {CustomErrorHandler} from "./services/error.handler.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(
      withFetch(),
      withInterceptors([accessTokenInterceptor()])
    ),
    AnalyticsService,
    SeoService,
    UserAccessService,
    UserAuthGuardService,
    AdminAuthGuardService,
    GuestAuthGuardService,
    { provide: UserData, useClass: UserService },
    { provide: ExchangeData, useClass: ExchangeService },
    { provide: LookupData, useClass: LookupService },
    { provide: InfoData, useClass: InfoDataService },
    { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService }, //Dirty hack to fix issue with using Default session storage provider on mobile for some magic events instead of local storage specified after provideAuth()
    provideAuth(authConfig),
    { provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService },
    provideEcharts(),
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ]
};


export function cast<T>(obj: any){
  return obj as T;
}
