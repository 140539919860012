<div class="about-us">
  <div class="about-us-inner">
    <div class="header">
      <h1 class="gradient-text">About Us</h1>
      <div class="background-shadow"></div>
      <div class="background-shadow-mob"></div>
      <p>Burvix Traders is a product of the law firm <strong>Manimama OÜ</strong>, which has been providing<br>
        services to cryptocurrency and fintech businesses since 2019. </p>
    </div>

    <div class="body">
      <p class="par-text-big">Cryptocurrency trading operations are carried out by the licensed company <strong>UAB Manimama Exchange</strong>, founded in 2021 and operating under the authorization of a Virtual Asset Service Provider in Lithuania.</p>

      <div class="body-inner">

        <div class="block">
          <div class="block-inner">
            <h2 class="gradient-text">Technology </h2>
            <p>Our algorithmic trading technology allows you to automatically profit in the cryptocurrency markets by making the optimal trades at the right time. </p>
            <span><p>Our bots work on exchanges 24/7 without human intervention.</p></span>
          </div>
          <div class="block-img-inner">
            <img src="/assets/images/about-us-pic/pic_1.png" alt="pic_1">
          </div>
        </div>

        <div class="line"></div>

        <div class="block reverse">
          <div class="block-inner">
            <h2 class="gradient-text">Strategy </h2>
            <p>Our methodology is based on the scientific research of Time Series Momentum. The trading strategy is based on a deeply redesigned multi-session, multi-iteration dollar cost averaging with dynamic parameters for each iteration. This strategy has been thoroughly tested and refined over three years through manual and automated trading.</p>
          </div>
          <div class="block-img-inner">
            <img src="/assets/images/about-us-pic/pic_2.png" alt="pic_2">
          </div>
        </div>

        <div class="line"></div>

        <div class="block">
          <div class="block-inner">
            <h2 class="gradient-text">Team</h2>
            <p>Our trading team consists of experienced traders with more than 10 years of experience in trading various types of assets. CTO is a cybersecurity expert and winner of many hackathons.</p>
          </div>
          <div class="block-img-inner">
            <img src="/assets/images/about-us-pic/pic_3.png" alt="pic_3">
          </div>
        </div>

        <div class="line"></div>

        <div class="block reverse new">
          <div class="block-inner">
            <h2 class="gradient-text">Security</h2>
            <p>We trade on spot markets without using leverage, so we have no liquidations at all. Security is our priority and we take all necessary measures to protect our clients' investments and financial information. To ensure maximum security, we use advanced technologies, including encryption and two-factor authentication.</p>
            <span>
              <p>Our trading bots run on a reliable virtual server, which ensures its stable operation 24/7, we have alternative backup servers to ensure continuous operation.</p>
            </span>
          </div>
          <div class="block-img-inner">
            <img src="/assets/images/about-us-pic/pic_4.png" alt="pic_3">
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
