import { Injectable } from '@angular/core';
import { CurrencyInfo, LookupData, PackageInfo, StrategyInfo } from '../abstract/lookup';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class LookupService extends LookupData {

    private readonly defaultStrategyId = 3;

    constructor(private http: HttpClient) {
        super();
    }

    getPackages(): Observable<PackageInfo[]> {
        return this.http.get<PackageInfo[]>(`${environment.apiUrl}/exchange/settings/packages`);
    }

    getStrategies(): Observable<StrategyInfo[]> {
        return this.http.get<StrategyInfo[]>(`${environment.apiUrl}/exchange/settings/strategies`);
    }

    getCurrencies(): Observable<{[key: string]: CurrencyInfo[]}> {
        return this.http.get<{[key: string]: CurrencyInfo[]}>(`${environment.apiUrl}/exchange/settings/currencies`);
    }

    get defaultStrategy() {
      return this.defaultStrategyId;
    }
}
